import React, { useState, useEffect, useContext, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Toolbar,
  AppBar,
  IconButton,
  Button,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Switch,
  Box,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Link,
  Divider,
  ListItemText,
  ListItemIcon,
  Badge,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TagManager from 'react-gtm-module';
import { getUserAttributes } from 'dock-shared/src/customerio-user';
import WarningIcon from '@mui/icons-material/Warning';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';
import CheckIcon from '@mui/icons-material/Check';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import DockLogo from '../../public/logo.svg';
import DockWhiteLogo from '../../public/logo-white.svg';
import Transition from './transition';
import ActiveLink from './active-link';
import UserAvatar from './user-avatar';
import ContactUsModal from '../modals/contact-us';
import Navbar from './navbar';
import getIsTestmode, { setIsTestmode } from '../utils/testmode';
import { getCookie, setCookie } from '../utils/cookies';
import { APIContext, reloadSession } from '../utils/api';
import CloseXWhite from '../../public/icons/closex.svg';
import { useNotificationData } from '../hooks/notifications';
import NotificationsMenu from './notifications/notifications-menu';

const navItemStyles = {
  fontSize: 14,
  fontWeight: 600,
  marginTop: '4px',
  display: 'flex',
  flexGrow: 0,
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 12px',
  marginRight: '16px',
  textDecoration: 'none',
  background: 'transparent',
  borderRadius: '6px',
  width: '100%',
  height: '36px',
  textAlign: 'left',
  transition: 'all 0.2s ease-in-out',
  color: '#52525B',
  '&:hover': {
    background: 'rgba(128, 128, 128, 0.075)',
    color: 'inherit',
  },
};

function getTrialDays(user) {
  if (!user.trial_expires) {
    return 0;
  }
  return Math.round((new Date(user.trial_expires) - new Date()) / (1000 * 60 * 60 * 24));
}

const TestmodeDisabledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function Layout({ children }) {
  const router = useRouter();
  const { data: session } = useSession();
  const { t } = useTranslation(['common']);
  const { setAPIKey } = useContext(APIContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [testMode, setTestMode] = useState(undefined);
  const [isDesktop, setIsDesktop] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showFreeTrialBanner, setShowTrialBanner] = useState(false);
  const [showStatusBanner, setShowStatusBanner] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(router.pathname.indexOf('/onboarding') > -1);
  const { unreadCount: notificationCount, notifications } = useNotificationData();
  const isDesktopMQ = useMediaQuery('(min-width:960px)');
  const selectedTeamId = useMemo(() => {
    if (session && session.user) {
      return session.user.team_id;
    }

    if (typeof window !== 'undefined') {
      return getCookie('selectedTeamId');
    }

    return null;
  }, [session]);

  const hideTestmodeBanner = isOnboarding || !session;
  const hideAppBar =
    isOnboarding ||
    router.pathname.indexOf('/credentials/issue') > -1 ||
    router.pathname.includes('/schemas/new') ||
    router.pathname.includes('/verification/create') ||
    router.pathname.includes('/verification/predefined-templates') ||
    router.pathname.includes('/ecosystems/join-ecosystem');
  const showSuspendedBar = !isOnboarding && session && session.user && session.user.status > 0;

  const showStatusMessage =
    showStatusBanner && !isOnboarding && !showSuspendedBar && session && session.user;

  const showManageBillingBar =
    !isOnboarding &&
    !showSuspendedBar &&
    !showStatusMessage &&
    session &&
    session.user &&
    session.user.subscription &&
    (session.user.subscription.status === 'past_due' ||
      session.user.subscription.invoice_status === 'open' ||
      (session.user.subscription.status === 'canceled' &&
        session.user.subscription.invoice_status === 'open'));

  const isFreeTrial =
    session &&
    session.user &&
    !session.user.subscription &&
    !showManageBillingBar &&
    !showSuspendedBar &&
    !showStatusMessage;

  const trialDays = isFreeTrial && getTrialDays(session.user);
  // Allow users to switch testmode off if they are on the settings page
  // This is needed so that users can delete their account
  const shouldDisableTestmodeSwitch =
    isFreeTrial && router.pathname !== '/settings' && router.pathname !== '/ecosystems';

  function handleHideTrialBanner(e) {
    e.stopPropagation();
    setShowTrialBanner(false);
  }

  function handleHideStatusBanner(e) {
    e.stopPropagation();
    setShowStatusBanner(false);
  }

  const routeChangeStart = () => {
    setIsLoading(true);
  };

  const routeChangeComplete = () => {
    setIsLoading(false);
    setTestMode(getIsTestmode());
  };

  useEffect(() => {
    setIsOnboarding(router.pathname.indexOf('/onboarding') > -1);
  }, [router, router.pathname]);

  useEffect(() => {
    if (testMode === undefined) {
      setTestMode(getIsTestmode());
    }

    setShowTrialBanner(!isOnboarding && isFreeTrial);
  }, [isOnboarding, isFreeTrial, testMode]);

  useEffect(() => {
    if (shouldDisableTestmodeSwitch && isFreeTrial && !getIsTestmode()) {
      handleSwitchTestmode({ target: { checked: true } });
    }
  }, [isFreeTrial, testMode, selectedTeamId, handleSwitchTestmode, shouldDisableTestmodeSwitch]);

  useEffect(() => {
    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeComplete);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeComplete);
    };
  }, [router.events]);

  useEffect(() => {
    const user = session && session.user;

    if (window.Intercom) {
      // Wrap in try/catch incase the intercom script gives us an error
      // dont want it to make Truvera Workspace error too
      try {
        const bootParams = user
          ? {
              app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
              name: user.name,
              email: user.email,
              user_id: user.id,
              created_at: Math.floor(new Date(user.created_at).getTime() / 1000),
            }
          : {
              app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
            };
        window.Intercom('boot', bootParams);
      } catch (e) {
        console.error('Intercom error', e);
      }
    }

    // Identify user in c.io
    if (user && window._cio) {
      window._cio.identify(getUserAttributes(user));
    }

    // Ensure we dont track GA on localhost
    if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
      TagManager.initialize({ gtmId: 'GTM-K885M4M' });
    }

    // Route change listener
    const handleRouteChange = (url) => {
      handleCloseAvatarMenu();
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pagePath: url,
          pageTitle: url,
        },
      });

      if (user && window._cio && user) {
        window._cio.identify(getUserAttributes(user));
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [session, router.events]);

  useEffect(() => {
    setIsDesktop(isDesktopMQ);
  }, [isDesktopMQ]);

  async function reloadData() {
    setIsLoading(true);

    // If we're multiple paths in, when switching a team/mode we want to prevent resource access error
    // EG: /ecosystems/{id} should reload as /ecosystems
    const splitPath = router.asPath.split('/');
    if (splitPath.length > 2) {
      await router.replace(`/${splitPath[1]}`);
    } else {
      await router.replace(router.asPath);
    }

    reloadSession();

    setIsLoading(false);
  }

  function handleClickNotifications(event) {
    setAnchorElNotifications(event.currentTarget);
  }

  function handleCloseNotificationsMenu() {
    setAnchorElNotifications(null);
  }

  function handleGotoSettings(e) {
    e.preventDefault();
    router.push('/settings');
  }

  function handleGotoTeamSettings(e) {
    e.preventDefault();
    router.push('/settings/team');
  }

  function handleSwitchTestmode(e) {
    setIsTestmode(e.target.checked);
    setTestMode(e.target.checked);
    setAPIKey(null);
    reloadData();
  }

  function handleShowContactUs() {
    setShowForm(true);
  }

  function handleCloseContact() {
    setShowForm(false);
  }

  function handleClickAvatar(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseAvatarMenu() {
    setAnchorEl(null);
  }

  function toggleDrawer() {
    setOpenDrawer(!openDrawer);
  }

  async function handleSignout() {
    handleCloseAvatarMenu();
    signOut({
      callbackUrl: '/?v=logout',
    });
    await router.replace('/');
  }

  function handleLink(e) {
    e.stopPropagation();
  }

  const handleSwitchTeam = async (team) => {
    try {
      setCookie('ecosystemId', '');
      setCookie('selectedTeamId', team.id);
      handleCloseAvatarMenu();

      setAPIKey(null);
      await reloadData();
    } catch (error) {
      // TODO snackbar
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        flexDirection: { xs: 'column', md: 'row' },
        ...(!session
          ? {
              background: 'url("auth-bg.png"), content-box radial-gradient(#000000, #00359E)',
              backgroundSize: 'cover',
              '&::before': {
                content: '""',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'url("auth-bg-texture.png")',
                mixBlendMode: 'overlay',
              },
            }
          : {}),
      }}>
      {session && (
        <Drawer
          anchor={isDesktop ? 'left' : 'right'}
          variant={isDesktop ? 'permanent' : undefined}
          open={isDesktop ? true : openDrawer}
          onClose={toggleDrawer}
          sx={{
            width: { md: '256px' },
            display: { md: 'flex' },
            flexDirection: { md: 'column' },
          }}
          PaperProps={{
            sx: {
              border: 'none',
              width: '256px',
              borderRight: '1px solid #E4E4E7',
            },
          }}
          style={
            isOnboarding
              ? {
                  display: 'none',
                }
              : undefined
          }>
          {testMode && !hideTestmodeBanner && (
            <Box
              sx={{
                width: '100%',
                height: '48px',
                background: '#EA580C',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 16,
                fontWeight: 600,
                color: '#ffffff',
              }}>
              TEST DATA
            </Box>
          )}

          <Box sx={{ padding: '8px 12px 8px 12px' }}>
            <DockLogo
              style={{
                marginTop: '12px',
                marginLeft: '18px',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px',
              }}>
              <Navbar session={session} onMail={handleShowContactUs} />

              {!isDesktop && (
                <a onClick={handleSignout} style={navItemStyles}>
                  Sign out
                </a>
              )}
            </Box>
          </Box>
        </Drawer>
      )}
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
        {isFreeTrial && (
          <ActiveLink href="/plans">
            <AppBar
              position="static"
              sx={{
                padding: '12px 32px',
                background: 'linear-gradient(45deg, #134DD4 0%, #0063F7 100%)',
                display: showFreeTrialBanner ? 'flex' : 'none',
                flexDirection: 'row',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '24px',
                boxShadow: 'none',
              }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '24px',
                  margin: '0 auto',
                  cursor: 'pointer',
                  color: '#ffffff',
                }}>
                {trialDays > 0 ? (
                  <>
                    Only {trialDays} days left on your free trial of test-only environment!
                    Subscribe by{' '}
                    <strong>
                      <u>selecting a plan</u>
                    </strong>{' '}
                    or{' '}
                    <strong>
                      <u>
                        <Link
                          href="https://www.dock.io/contact-sales"
                          target="_blank"
                          rel="noreferrer"
                          onClick={handleLink}
                          sx={{
                            color: '#FFFFFF',
                            '&:visited': {
                              color: '#FFFFFF',
                            },
                          }}>
                          talking to our sales team
                        </Link>
                      </u>
                    </strong>
                    .{' '}
                  </>
                ) : (
                  <>
                    Your free trial has ended, please <u>select a plan</u> or{' '}
                    <u>
                      <Link
                        href="https://www.dock.io/contact-sales"
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleLink}
                        sx={{
                          color: '#FFFFFF',
                          '&:visited': {
                            color: '#FFFFFF',
                          },
                        }}>
                        talk to sales
                      </Link>
                    </u>{' '}
                    to continue.
                  </>
                )}
              </Typography>
              <CloseXWhite
                onClick={handleHideTrialBanner}
                style={{
                  cursor: 'pointer',
                }}
              />
            </AppBar>
          </ActiveLink>
        )}
        {showManageBillingBar && (
          <AppBar
            position="static"
            style={{
              padding: '12px 32px',
              backgroundColor: '#FCD34D',
              display: 'flex',
              flexDirection: 'row',
              boxShadow: 'none',
            }}>
            <WarningIcon
              style={{ color: '#B45309', margin: 'auto 25px auto 0', marginRight: '25px' }}
            />
            <Typography variant="body1" style={{ maxWidth: '70%', fontSize: 14 }}>
              {session.user.subscription.status === 'canceled' &&
              session.user.subscription.invoice_status === 'open'
                ? 'We’ve cancelled your plan due to multiple failed attempts to charge your credit card. To activate your account again and use transactions, you’ll need to make a payment of your full outstanding balance.'
                : "We were unable to process payment for your recurring plan. If you've changed cards recently or your card has expired you can update your billing info to restore full access. If your card requires authentication, you must make the payment manually."}
            </Typography>
            <ActiveLink href="/plans">
              <Button
                href="/plans"
                component="a"
                variant="outlined"
                style={{
                  flexShrink: 0,
                  margin: 'auto 0',
                  marginLeft: 'auto',
                  border: '2px solid #27272A',
                }}>
                Manage Billing
              </Button>
            </ActiveLink>
          </AppBar>
        )}
        {showSuspendedBar && (
          <AppBar
            position="static"
            style={{
              padding: '12px 32px',
              backgroundColor: '#FCD34D',
              display: 'flex',
              flexDirection: 'row',
              boxShadow: 'none',
            }}>
            <WarningIcon
              style={{ color: '#B45309', margin: 'auto 25px auto 0', marginRight: '25px' }}
            />
            <Typography variant="body1" style={{ maxWidth: '70%' }}>
              Your account has been suspended for either non-payment or misuse, please contact
              support if you believe this to be an error.
            </Typography>
          </AppBar>
        )}
        {showStatusMessage && (
          <AppBar
            position="static"
            style={{
              padding: '12px 32px',
              background: 'linear-gradient(45deg, #134DD4 0%, #0063F7 100%)',
              display: 'flex',
              flexDirection: 'row',
              boxShadow: 'none',
            }}>
            <Typography variant="body1" sx={{ color: '#FFFFFF', margin: '0 auto' }}>
              Upcoming Service Downtime: Test Data Migration on January 28, 19:00- 22:00 UTC{' '}
              <u>
                <Link
                  href="https://docs.truvera.io/workspace/truvera-workspace-release-notes/service-downtime-test-data-migration-on-january-22"
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleLink}
                  sx={{
                    color: '#FFFFFF',
                    '&:visited': {
                      color: '#FFFFFF',
                    },
                  }}>
                  Read more
                </Link>
              </u>
            </Typography>
            <CloseXWhite
              onClick={handleHideStatusBanner}
              style={{
                cursor: 'pointer',
              }}
            />
          </AppBar>
        )}

        <AppBar
          position="static"
          color="inherit"
          sx={{
            borderBottom: { xs: '1px solid #e5e7eb', md: 'none' },
            marginBottom: (theme) => theme.spacing(2),
            boxShadow: 'none',
            display: hideAppBar ? 'none' : 'flex',
          }}>
          <Toolbar
            sx={{
              paddingTop: 0,
              minHeight: '60px',
              paddingLeft: 0,
              paddingRight: 4,
              background: 'transparent',
            }}
            disableGutters={!session}>
            {!session && (
              <Box
                sx={{
                  padding: '8px 12px 8px 12px',
                  margin: '0 auto',
                  width: { xs: '95%', md: '95%', lg: '90%' },
                  maxWidth: '1200px',
                }}>
                <a href="https://dock.io" target="_blank" rel="noreferrer noopener">
                  <DockWhiteLogo
                    style={{
                      marginTop: '12px',
                      marginLeft: '18px',
                    }}
                  />
                </a>
              </Box>
            )}
            <Box
              sx={{
                margin: 'auto 0 auto 0',
                marginLeft: 'auto',
                alignItems: 'center',
                display: !session ? 'none' : { xs: 'none', md: 'flex' },
              }}>
              <TestmodeDisabledTooltip
                title={
                  isFreeTrial && (
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#FFF',
                        maxWidth: '200px',
                      }}>
                      Test data only. Please{' '}
                      <Link legacyBehavior href="/plans" sx={{ color: '#FFF' }}>
                        <u>select a plan</u>
                      </Link>{' '}
                      to access production data.
                    </Typography>
                  )
                }
                arrow
                describeChild>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={testMode || false}
                        onChange={handleSwitchTestmode}
                        inputProps={{ 'aria-label': 'controlled' }}
                        id="testmode-switch"
                        disabled={shouldDisableTestmodeSwitch}
                      />
                    }
                    label="Test mode"
                    sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '20px', marginRight: 0 }}
                    disableTypography
                  />
                </FormGroup>
              </TestmodeDisabledTooltip>

              <Box sx={{ marginLeft: '32px', marginRight: '12px' }}>
                <Badge
                  color="primary"
                  badgeContent={notificationCount}
                  sx={{ cursor: 'pointer' }}
                  onClick={handleClickNotifications}>
                  <NotificationsIcon sx={{ color: '#3F3F46' }} fontSize="medium" />
                </Badge>
              </Box>

              <Box
                sx={{
                  marginLeft: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleClickAvatar}>
                <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '8px' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#3F3F46',
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '16px',
                      textAlign: 'right',
                    }}>
                    {session?.user?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    id="selected-team"
                    sx={{
                      color: 'text.secondary',
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      textAlign: 'right',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: '140px',
                    }}>
                    {session?.user?.team_name ||
                      t('common:team-name-default', {
                        username: session?.user?.team_owner_name,
                      })}
                  </Typography>
                </Box>

                <IconButton
                  aria-label="open menu"
                  component="span"
                  id="avatar-menu"
                  onClick={handleClickAvatar}
                  sx={{ borderRadius: '100vw' }}>
                  <UserAvatar
                    user={session?.user}
                    sx={{
                      width: 32,
                      height: 32,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>

            <IconButton
              aria-label="open menu"
              component="span"
              onClick={toggleDrawer}
              sx={{
                margin: 'auto 0',
                marginLeft: 'auto',
                display: { xs: 'flex', md: 'none' },
              }}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {session && (
          <NotificationsMenu
            anchorEl={anchorElNotifications}
            open={Boolean(anchorElNotifications)}
            onClose={handleCloseNotificationsMenu}
            notifications={notifications}
          />
        )}

        {session && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseAvatarMenu}
            keepMounted
            dense
            PaperProps={{ sx: { minWidth: 240 } }}>
            <MenuItem onClick={handleGotoSettings} disabled>
              <UserAvatar
                user={session.user}
                sx={{
                  width: 32,
                  height: 32,
                  marginRight: 1,
                }}
              />
              <ListItemText primary={session.user.name} secondary={session.user.email} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleGotoSettings}>
              <ListItemIcon>
                <SettingsIcon sx={{ color: '#1C1B1F' }} fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" sx={{ fontWeight: '500' }}>
                Settings
              </Typography>
            </MenuItem>
            <MenuItem key="team-settings-btn" onClick={handleGotoTeamSettings}>
              <ListItemIcon>
                <GroupsIcon sx={{ color: '#1C1B1F' }} fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" sx={{ fontWeight: '500' }}>
                Team
              </Typography>
            </MenuItem>
            <Divider key="team-settings-divider" />
            {session.user.teams?.length > 1 && [
              <MenuItem key="switch-team" sx={{ fontWeight: 700, fontSize: 12 }} disabled>
                Switch team
              </MenuItem>,
              ...session.user.teams.map((team) => (
                <MenuItem key={team.id} onClick={() => handleSwitchTeam(team)}>
                  <Avatar
                    alt={team.name}
                    src={team.team_image}
                    sx={{
                      width: 32,
                      height: 32,
                      marginRight: 1,
                    }}
                  />
                  <ListItemText>
                    {team.name || t('common:team-name-default', { username: team.team_owner_name })}
                  </ListItemText>
                  {Number(selectedTeamId) === team.id && (
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                  )}
                </MenuItem>
              )),
              <Divider key="teams-divider" />,
            ]}
            <MenuItem onClick={handleSignout}>
              <ListItemIcon>
                <LogoutIcon sx={{ color: '#1C1B1F' }} fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" sx={{ fontWeight: '500' }}>
                Sign out
              </Typography>
            </MenuItem>
          </Menu>
        )}

        <Transition location={router.pathname}>
          <Box
            sx={{
              margin: '0 auto 40px auto',
              maxWidth: { xs: '95%', md: '95%', lg: '90%' },
            }}>
            {isLoading ? (
              <CircularProgress
                sx={{
                  margin: '100px auto',
                  display: 'block',
                }}
              />
            ) : (
              children
            )}
          </Box>
        </Transition>
      </div>

      <ContactUsModal open={!!showForm} onClose={handleCloseContact} />
    </Box>
  );
}
